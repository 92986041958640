import React from "react"
import { Link, StaticQuery } from "gatsby"
import Image from "gatsby-image"

import Toggle from "./toggle"
import ThemeContext from "../utils/theme-context.js"
import { rhythm, scale } from "../utils/typography"

class Header extends React.Component {
  render() {
    const { location, title } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let headerContent

    if (location.pathname === rootPath) {
      headerContent = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
    } else {
      headerContent = (
        <h3
          style={{
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
    }

    return (
      <StaticQuery
        query={iconQuery}
        render={data => (
          <ThemeContext.Consumer>
            {theme => (
              <div
                className={theme.dark ? "dark" : "light"}
                style={{ display: "flex", "justifyContent": "space-between" }}
              >
                {headerContent}
                <div>
                  <Toggle
                    icons={{
                      checked: (
                        <Image
                          fixed={data.newMoon.childImageSharp.fixed}
                          width="16"
                          height="16"
                          role="presentation"
                          style={{ pointerEvents: "none" }} />
                      ),
                      unchecked: (
                        <Image
                          fixed={data.fullMoon.childImageSharp.fixed}
                          width="16"
                          height="16"
                          role="presentation"
                          style={{ pointerEvents: "none" }} />
                      ),
                    }}
                    checked={theme.dark}
                    onChange={theme.toggleDark}
                  />
                </div>
              </div>
            )}
          </ThemeContext.Consumer>
        )}
      />

    )
  }
}

const iconQuery = graphql`
  query IconQuery {
    newMoon: file(absolutePath: { regex: "/new-moon.png/" }) {
      childImageSharp {
        fixed(width: 16, height: 16) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fullMoon: file(absolutePath: { regex: "/full-moon.png/" }) {
      childImageSharp {
        fixed(width: 16, height: 16) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`

export default Header