import React from "react";

import Header from './header';
import { rhythm } from "../utils/typography";
import ThemeContext from '../utils/theme-context.js'
import '../utils/global.css';


class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props

    return (
      <ThemeContext.Consumer>
        {theme => (
          <div className={theme.dark ? 'dark' : 'light'} style={{ minHeight: `100% `}}>
            <div
              style={{
                marginLeft: `auto`,
                marginRight: `auto`,
                maxWidth: rhythm(24),
                padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
              }}
            >
              <Header location={location} title={title} />
              <main>{children}</main>
              <footer>
                © {new Date().getFullYear()}, Built with
          {` `}
                <a style={{ 'textDecoration': 'none'}} href="https://www.gatsbyjs.org"><span role="img" aria-label="Loudly Crying Face Emoji">😂</span>/<span role="img" aria-label="Tears of Joy Emoji">😭</span></a>
              </footer>
            </div>
          </div>
        )}
      </ThemeContext.Consumer>
    )
  }
}

export default Layout
